import React, { FC } from 'react';
import { UserService } from '../../services/user';
import { translate } from '../../languages';
import { Icon } from '../icon';
import { NavLink, useLocation } from 'react-router-dom';
import { Routes } from '../../AppRoutes';
import { useSelector } from '../../store';

export const Navigator: FC = () => {
  return (
    <div className="Navigator">
      <img
        src="/assets/images/logo-symbol-white1.png"
        alt=""
        className="logo"
      />
      <MenuLinks />
    </div>
  );
};

const MenuLinks: FC = () => {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);

  return (
    <div className="menu">
      {/* <NavLink exact to={Routes.dashboardSuperAdmin.path}>
        <Icon.Dashboard />
        Dashboard
      </NavLink> */}

      {/* <NavLink to={Routes.userList.path}>
                <Icon.User />
                Users
            </NavLink> */}

      {/* <NavLink to={Routes.transactionList.path}>
                <Icon.Exchange />
                Transactions
            </NavLink> */}

      {/* <NavLink to={Routes.ClaimList.path}>
                <Icon.ClaimIcon />
                Claim
            </NavLink> */}

      {/* <NavLink to={Routes.franchiseTable.path}>
                <Icon.Affiliate />
                Franchise
            </NavLink> */}

      {/* <NavLink to={Routes.reportSystem.path}>
                <Icon.Report />
                Reports
            </NavLink> */}

      {/* <NavLink to={Routes.withdrawConfig.path}>
                <Icon.Setting />
                Config
            </NavLink> */}

      {/* <div className="btnLogout" onClick={() => UserService.logout()}>
                <Icon.Logout />
                {translate('logout')}
            </NavLink> */}

      <NavLink
        exact
        to={Routes.userList.path}
        isActive={() => {
          return pathname.includes('/users');
        }}
      >
        <Icon.User />
        Users
      </NavLink>

      <NavLink to={Routes.transactionList.path}>
        <Icon.TransactionIcon />
        Transactions
      </NavLink>

      {/* <NavLink to={Routes.ExperienceGameList.path}>
        <Icon.ExperienceGameIcon />
        Experience Game
      </NavLink> */}

      {/* <NavLink to={Routes.ClaimList.path}>
        <Icon.ClaimIcon />
        Claim
      </NavLink> */}

      <NavLink to={Routes.franchiseTable.path}>
        <Icon.Affiliate />
        Franchise
      </NavLink>

      {user?.userRole === 'SUPER_ADMIN' && (
        <NavLink to={Routes.dividends.path}>
          <Icon.Dividends />
          Dividends
        </NavLink>
      )}

      {/* <NavLink
        to={Routes.OrdersPage.path}
        isActive={() => {
          return (
            pathname.includes("/game-management/orders") ||
            pathname.includes("/game-management/supplier") ||
            pathname.includes("/game-management/game")
          );
        }}
      >
        <Icon.GameManagementIcon />
        Game Management
      </NavLink> */}

      {/* <NavLink to={Routes.GameOrdersList.path}>
        <Icon.GameOrdersIcon />
        Orders
      </NavLink> */}

      {/* <NavLink to={Routes.Pool.path}>
        <Icon.GameOrdersIcon />
        Pool
      </NavLink> */}

      <NavLink 
        to={Routes.startupList.path}
        isActive={() => {
          return pathname.includes(Routes.startupApprovalForCallingCapital.path) 
                  // || pathname.includes(Routes.startupInvestmentHistory.path) 
                  || pathname.includes(Routes.startupDetail.path)
                  || pathname.includes(Routes.startupList.path)
                  || pathname.includes(Routes.startupContract.path)
          }}
      >
        <Icon.StartupManagementIcon />
        Startup
      </NavLink>

      <NavLink to={Routes.affiliatedAgent.path}>
        <Icon.Agency />
        Affiliated Agent
      </NavLink>

      <NavLink
        to={Routes.WaitingApprovalProducts.path}
        isActive={() => {
          return pathname.includes('/product-management/');
        }}
      >
        <Icon.Product />
        Products Management
      </NavLink>

      {/* <NavLink
        to={Routes.kyc.path}
        isActive={() => {
          return pathname.includes('/kyc');
        }}
      >
        <Icon.Product />
        KYC
      </NavLink> */}

      <NavLink to={Routes.reportSystem.path}>
        <Icon.Report />
        Reports
      </NavLink>
      <NavLink
        to={Routes.withdrawConfig.path}
        isActive={() => {
          return (
            pathname.includes('change-password') ||
            pathname.includes('maintenance') ||
            pathname.includes('activities') ||
            pathname.includes('announcement') ||
            pathname.includes('role-permission')
          );
        }}
      >
        <Icon.Setting />
        Settings
      </NavLink>

      <div className="btnLogout" onClick={() => UserService.logout()}>
        <Icon.Logout />
        {translate('logout')}
      </div>
    </div>
  );
};
