import Cookies from "universal-cookie";
const cookies = new Cookies();

const DefaultConfigs = {
  ENV: "local",

  // General information
  PUBLIC_URL: "http://localhost:3200",
  NUMBER_DECIMAL_DISPLAY: 8,

  // RESTful APIs
  URL_API_MAIN_CLIENT_SIDE: "https://gpc-api-911ba739ee.fizzgyp.fun/api",
  URL_SOCKET: "https://gpc-api-911ba739ee.fizzgyp.fun",
  URL_SOCKET_APP_ID: "J215ZvbkADfcnnLK",
  URL_API_MAIN_STORAGE_SIDE: "https://gpc-api-911ba739ee.fizzgyp.fun/storage",

  GOOGLE_RECAPTCHA_KEY: "6LcK3DApAAAAAN4p3P1rl3FZo4cULDIWL9Igb5-2",
  TRADE_REAL_COIN_CODE: "MAIN",
  TRADE_DEMO_COIN_CODE: "DEMO",
  PASSWORD_SUPERADMIN: "123123123",
  BSC_SCAN: "https://testnet.bscscan.com/tx/",
  TRON_SCAN: "https://nile.tronscan.org/#/transaction/",
  ETHER_SCAN: "https://sepolia.etherscan.io/tx/",

  RECEIVER_EMAIL: "ykyfyyofdbkrg@emergentvillage.org",
};

export function getEnv(
  key:
    | "ENV"

    // General information
    | "PUBLIC_URL"
    | "NUMBER_DECIMAL_DISPLAY"

    // RESTful APIs
    | "URL_API_MAIN_CLIENT_SIDE"
    | "URL_SOCKET"
    | "URL_SOCKET_APP_ID"
    | "URL_API_MAIN_STORAGE_SIDE"
    | "GOOGLE_RECAPTCHA_KEY"
    | "TRADE_REAL_COIN_CODE"
    | "TRADE_DEMO_COIN_CODE"
    | "PASSWORD_SUPERADMIN"
    | "BSC_SCAN"
    | "TRON_SCAN"
    | "ETHER_SCAN"
    | "RECEIVER_EMAIL"
): string {
  return cookies.get(key) || DefaultConfigs[key];
}

export const isDev = getEnv("ENV") !== "production";

export const CollectionDisplayOptions = [
  {
    label: "Trang chủ",
    value: "home",
  },
  {
    label: "Dành cho người mới",
    value: "new-member",
  },
  {
    label: "Trang sản phẩm",
    value: "product-page",
  },
];
