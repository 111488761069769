import React, { FC, useEffect, useRef, useState } from "react";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { Box, InputDateTime, InputText } from "../../../components";
import { InputImageSingle } from "../../../components/single-image";
import { useForm, CreateAlert, InputWraper, Button, Icon } from "../../../modules";
import * as Yup from "yup";
import { StartupService } from "../../../services/startup";
import { EditorForm } from "../../../components/ckeditor-compnt";
import { Routes } from "../../../AppRoutes";
import { useHistory } from "react-router-dom";
import { ReasonForm } from "../components/reason";
import { PageHead } from "../../../components/page-head";
import { translate } from "../../../languages";
import levenshtein from "js-levenshtein";
import validator from "validator";

import SignatureCanvas from "react-signature-canvas";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { useDeviceType } from "../../../hook";

export const MIN_INVESTMENT_LIMIT = 20;
export const MAX_IMAGE_UPLOAD = 5;

export enum EStartupTypeCode {
    STORE = "STORE",
    GTC_LAND = "GTC_LAND",
    GTC_MOVE = "GTC_MOVE",
    GTC_TRAVEL = "GTC_TRAVEL",
}

export let getInputLabelByInputName = (startupTypeCode:any, inputName:any) => {
    if (inputName === "projectName") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Project/Store Name");
            case EStartupTypeCode.GTC_LAND:
                return translate("Property Name");
            case EStartupTypeCode.GTC_TRAVEL:
                return translate("Service/Tour/Product Name");
            default:
                return translate("Project Name");
        }
    }

    if (inputName === "photoUrl") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Store Image");
            case EStartupTypeCode.GTC_LAND:
                return translate("Property Image");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Vehicle Image");
            case EStartupTypeCode.GTC_TRAVEL:
                return translate("Illustration Image");
            default:
                return translate("Images");
        }
    }

    if (inputName === "licenseFrontUrl") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Front of business license");
            case EStartupTypeCode.GTC_LAND:
                return translate("Front of ownership certificate");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Front of ownership certificate");
            default:
                return translate("Front Of License");
        }
    }

    if (inputName === "licenseBackUrl") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Back of business license");
            case EStartupTypeCode.GTC_LAND:
                return translate("Back of ownership certificate");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Back of ownership certificate");
            default:
                return translate("Back Of License");
        }
    }

    if (inputName === "mapDetail") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Store/Business Address");
            case EStartupTypeCode.GTC_LAND:
                return translate("Property Address");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Owner Address");
            default:
                return translate("Address");
        }
    }
};

export let getInputTooltipByInputName = (startupTypeCode:any, inputName:any) => {
    if (inputName === "projectName") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Fill in the name of the business/business/store as written on the business license");
            case EStartupTypeCode.GTC_LAND:
                return translate("Fill in the name of the property");
            case EStartupTypeCode.GTC_TRAVEL:
                return translate("Service/Tour/Product Name, for example: Nam Du island tour");
            default:
                return translate("Project Name");
        }
    }

    if (inputName === "representativeName") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Fill in the name of the representative on the business license. At the same time, it is also the account owner who creates the project");
            case EStartupTypeCode.GTC_LAND:
                return translate("Fill in the name of the representative on the property ownership certificate. At the same time, it is also the account owner who creates the project");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Fill in the name of the representative on the property ownership certificate. At the same time, it is also the account owner who creates the project");
            case EStartupTypeCode.GTC_TRAVEL:
                return translate("Project owner name");
            default:
                return translate("Project owner name");
        }
    }

    if (inputName === "representativeEmail") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Contracts and related information will be sent to your email if any");
            case EStartupTypeCode.GTC_LAND:
                return translate("Contracts and related information will be sent to your email if any");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Contracts and related information will be sent to your email if any");
            case EStartupTypeCode.GTC_TRAVEL:
                return translate("Project owner email");
            default:
                return translate("Project owner email");
        }
    }

    if (inputName === "projectDescription") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Introduce in detail about your store to attract customers");
            case EStartupTypeCode.GTC_LAND:
                return translate("Introduce in detail about the property");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Detailed introduction of assets");
            case EStartupTypeCode.GTC_TRAVEL:
                return translate("Detailed description of the service");
            default:
                return translate("Detailed description of the service");
        }
    }

    if (inputName === "photoUrl") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Take a photo of your store, recommend horizontal photo");
            case EStartupTypeCode.GTC_LAND:
                return translate("Take a photo of the property, recommend horizontal photo");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Take a photo of the asset, recommend horizontal photo");
            case EStartupTypeCode.GTC_TRAVEL:
                return translate("Post a few illustrative images of your service");
            default:
                return translate("Images");
        }
    }

    if (inputName === "licenseFrontUrl") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Take a photo of the front business license. Note that the image must be clear and not blurred for the approval process to be faster");
            case EStartupTypeCode.GTC_LAND:
                return translate("Take a photo of all sides of the ownership certificate");
            case EStartupTypeCode.GTC_MOVE:
                return translate("Take a photo of all sides of the ownership certificate");
            default:
                return translate("Front Of License");
        }
    }

    if (inputName === "licenseBackUrl") {
        switch (startupTypeCode) {
            case EStartupTypeCode.STORE:
                return translate("Take a photo of the back business license. Note that the image must be clear and not blurred for the approval process to be faster");
                case EStartupTypeCode.GTC_LAND:
                    return translate("Take a photo of all sides of the ownership certificate");
                case EStartupTypeCode.GTC_MOVE:
                    return translate("Take a photo of all sides of the ownership certificate");
            default:
                return translate("Back Of License");
        }
    }
};

export const StartupDetail: FC = () => {
    const router:any = useRouteMatch();
    const navigate = useHistory();

    const startupId = +router?.params?.id ?? -1;

    const userSignatureCanvas = useRef<any>(null);

    const deviceType = useDeviceType();
    const [startupDetail, setStartupDetail] = useState<any>();
    const [imageArray, setImageArray] = useState<any>([]);
    const [approvalStatus, setApprovalStatus] = useState<any>("");
    const [openReasonForm, setOpenReasonForm] = useState(false);

    const { getInputProps, handleSubmit, isSubmitting, getValue } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "projectName",
                validate: startupDetail?.agencyType !== EStartupTypeCode.GTC_MOVE ? Yup.string().required(translate("Must be provided")) : null,
                defaultValue: startupDetail?.projectName,
            },
            {
                name: "totalAmountContribution",
                validate:
                    startupDetail?.agencyType === EStartupTypeCode.STORE
                        ? Yup.number()
                            .required(translate("Must be provided"))
                            .typeError(translate("Must be a number"))
                            .test("multiple-of-20", translate("Please Enter A Multiple Of {var1}", { var1: MIN_INVESTMENT_LIMIT }), (value:any) => value % MIN_INVESTMENT_LIMIT === 0)
                        : null,
                defaultValue: startupDetail?.totalAmountContribution || "0",
            },
            {
                name: "representativeName",
                validate: Yup.string().required(translate("Must be provided")),
                defaultValue: startupDetail?.representativeName,
                isDisabled: true,
            },
            {
                name: "representativeEmail",
                defaultValue: startupDetail?.representativeEmail,
                isDisabled: true,
            },
            {
                name: "phoneNumber",
                validate: Yup.string().required(translate("Must be provided")),
                defaultValue: startupDetail?.phoneNumber,
                isDisabled: true,
            },
            {
                name: "personalId",
                // defaultValue: user?.identification?.personalId,
                defaultValue: startupDetail?.personalNumberId,
                isDisabled: true,
            },
            {
                name: "kindOfBussiness",
                validate: startupDetail?.agencyType === EStartupTypeCode.STORE ? Yup.string().required(translate("Must be provided")) : null,
                defaultValue: startupDetail?.kindOfBussiness,
            },
            {
                name: "projectDescription",
                defaultValue: startupDetail?.projectDescription ?? "<p></p>",
            },
            {
                name: "photoUrl",
                defaultValue: {
                    file: {
                        name: JSON.parse(startupDetail?.photoUrl ?? "[{}]")?.[0]?.url,
                    },
                    src: JSON.parse(startupDetail?.photoUrl ?? "[{}]")?.[0]?.url,
                },
            },
            {
                name: "licenseFrontUrl",
                defaultValue:
                    JSON.parse(startupDetail?.licenseUrl ?? "[]")?.length >= 1 ? {
                            file: {
                                name: JSON.parse(startupDetail?.licenseUrl ?? "[]")?.[0]?.url,
                            },
                            src: JSON.parse(startupDetail?.licenseUrl ?? "[]")?.[0]?.url,
                        }
                        : null,
            },
            {
                name: "licenseBackUrl",
                defaultValue:
                    JSON.parse(startupDetail?.licenseUrl ?? "[]")?.length >= 2 ? {
                            file: {
                                name: JSON.parse(startupDetail?.licenseUrl ?? "[]")?.[1]?.url,
                            },
                            src: JSON.parse(startupDetail?.licenseUrl ?? "[]")?.[1]?.url,
                        }
                        : null,
            },
            {
                name: "mapDetail",
                validate: startupDetail?.agencyType !== EStartupTypeCode.GTC_TRAVEL ? Yup.string().required(translate("Must be provided")) : null,
                defaultValue: startupDetail?.mapDetail?.rawAddress,
            },
            {
                name: "originalPrice",
                validate:
                    startupDetail?.agencyType !== EStartupTypeCode.STORE
                        ? Yup.number()
                            .required(translate("Must be provided"))
                            .typeError(translate("Must be a number"))
                            .test("multiple-of-20", translate("Please Enter A Multiple Of {var1}", { var1: MIN_INVESTMENT_LIMIT }), (value:any) => value % MIN_INVESTMENT_LIMIT === 0)
                        : null,
                defaultValue: startupDetail?.originalPrice,
            },
            {
                name: "price",
                validate:
                    startupDetail?.agencyType !== EStartupTypeCode.STORE
                        ? Yup.number()
                            .required(translate("Must be provided"))
                            .typeError(translate("Must be a number"))
                            .test("greater-than-originalPrice", translate("Price must be greater than Original Price"), (value:any) => value > getInputProps("originalPrice").value)
                        : null,
                defaultValue: startupDetail?.price,
            },
            {
                name: "vehicleModel",
                validate: startupDetail?.agencyType === EStartupTypeCode.GTC_MOVE ? Yup.string().required(translate("Must be provided")) : null,
                defaultValue: startupDetail?.vehicleModel,
            },
            {
                name: "numberPlate",
                validate: startupDetail?.agencyType === EStartupTypeCode.GTC_MOVE ? Yup.string().required(translate("Must be provided")) : null,
                defaultValue: startupDetail?.numberPlate,
            },
            {
                name: "salesQuantity",
                validate:
                    startupDetail?.agencyType === EStartupTypeCode.GTC_TRAVEL
                        ? Yup.number()
                            .required(translate("Must be provided"))
                            .typeError(translate("Must be a number"))
                            .integer(translate("Must be an integer"))
                            .min(1, translate("Must be greater than {var1}", { var1: 0 }))
                        : null,
                defaultValue: startupDetail?.salesQuantity,
            },
            {
                name: "saleEndDate",
                validate: startupDetail?.agencyType === EStartupTypeCode.GTC_TRAVEL ? Yup.string().required(translate("Must be provided")) : null,
                defaultValue: startupDetail?.saleEndDate,
            },
        ],
        onSubmit: async (values) => {
            if (imageArray.length === 0) {
                return CreateAlert({
                    message: translate("Please upload at least one image"),
                    type: "warning",
                });
            }

            if (values.licenseFrontUrl?.src == null && startupDetail?.agencyType !== EStartupTypeCode.GTC_TRAVEL) {
                return CreateAlert({
                    message: translate("Please upload the front of license"),
                    type: "warning",
                });
            }

            if (values.licenseBackUrl?.src == null && startupDetail?.agencyType !== EStartupTypeCode.GTC_TRAVEL) {
                return CreateAlert({
                    message: translate("Please upload the back of license"),
                    type: "warning",
                });
            }

            let payload: any;

            if (startupDetail?.agencyType === EStartupTypeCode.STORE) {
                payload = {
                    agencyId: +startupId,
                    agencyType: startupDetail?.agencyType,
                    projectName: values.projectName,
                    totalAmountContribution: +values.totalAmountContribution,
                    representativeName: values.representativeName,
                    phoneNumber: values.phoneNumber,
                    representativeEmail: values.representativeEmail,
                    personalNumberId: values.personalId,
                    kindOfBussiness: values.kindOfBussiness,
                    projectDescription: values.projectDescription,
                    photoUrl: imageArray,
                    licenseUrl: [values.licenseFrontUrl?.src, values.licenseBackUrl?.src],
                    mapDetail: { raw_address: values?.mapDetail },
                    ownerSignature: startupDetail?.ownerSignature,
                };
            }
            if (startupDetail?.agencyType === EStartupTypeCode.GTC_LAND) {
                payload = {
                    agencyId: +startupId,
                    agencyType: startupDetail?.agencyType,
                    projectName: values.projectName,
                    representativeName: values.representativeName,
                    phoneNumber: values.phoneNumber,
                    representativeEmail: values.representativeEmail,
                    personalNumberId: values.personalId,
                    projectDescription: values.projectDescription,
                    photoUrl: imageArray,
                    licenseUrl: [values.licenseFrontUrl?.src, values.licenseBackUrl?.src],
                    mapDetail: { raw_address: values?.mapDetail },
                    ownerSignature: startupDetail?.ownerSignature,
                    originalPrice: +values.originalPrice,
                    price: +values.price,
                };
            }
            if (startupDetail?.agencyType === EStartupTypeCode.GTC_MOVE) {
                payload = {
                    agencyId: +startupId,
                    agencyType: startupDetail?.agencyType,
                    representativeName: values.representativeName,
                    phoneNumber: values.phoneNumber,
                    representativeEmail: values.representativeEmail,
                    personalNumberId: values.personalId,
                    projectDescription: values.projectDescription,
                    photoUrl: imageArray,
                    licenseUrl: [values.licenseFrontUrl?.src, values.licenseBackUrl?.src],
                    mapDetail: { raw_address: values?.mapDetail },
                    ownerSignature: startupDetail?.ownerSignature,
                    originalPrice: +values.originalPrice,
                    price: +values.price,
                    vehicleModel: values.vehicleModel,
                    numberPlate: values.numberPlate,
                };
            }
            if (startupDetail?.agencyType === EStartupTypeCode.GTC_TRAVEL) {
                payload = {
                    agencyId: +startupId,
                    agencyType: startupDetail?.agencyType,
                    projectName: values.projectName,
                    representativeName: values.representativeName,
                    phoneNumber: values.phoneNumber,
                    representativeEmail: values.representativeEmail,
                    personalNumberId: values.personalId,
                    projectDescription: values.projectDescription,
                    photoUrl: imageArray,
                    originalPrice: +values.originalPrice,
                    price: +values.price,
                    salesQuantity: +values.salesQuantity,
                    saleEndDate: values.saleEndDate,
                };
            }
            return StartupService.updateStartup(payload)
                .then((res) => {
                    CreateAlert({ message: "Edit startup successfully", type: "success" });
                    navigate.push(Routes.startupList.path);
                })
                .catch((err) => {
                    CreateAlert({ message: translate(err.message), type: "danger" });
                });
        },
    });

    const onApprove = () => {
        StartupService.updateStartupStatus({ agencyId: startupId, approvalStatus: 1 }).then(() => {
            CreateAlert({ message: "Approve startup successfully", type: "success" });
            navigate.push(Routes.startupList.path);
        });
    };

    let handleOnClickDeleteImages = (element: any, idx: number) => {
        setImageArray((state: any) => {
            const newState = [...state];
            newState.splice(idx, 1);
            return newState;
        });
    };

    useEffect(() => {
        StartupService.getStarupList({ page: 1, pageSize: 1, agencyId: startupId }).then((res: any) => {
            setImageArray(JSON.parse(res?.data[0]?.photoUrl ?? "[{}]")?.map((element: any) => element.url) ?? []);
            setStartupDetail(res?.data[0] ?? {});
            setApprovalStatus(res?.data?.[0]?.approvalStatus);
        });
    }, []);

    if (!startupDetail)
        return (
            <div className="user-row justify-content-center">
                <div className={deviceType === "Desktop" ? "user-col-14" : "user-col-18"}>
                    <div className="d-flex justify-content-center">
                        <Icon.Loading />
                    </div>
                </div>
            </div>
        );

    return (
        <div className="StartupDetail">
            <PageHead urlPath={Routes.startupList.path} title="Startup list" />
            <div className="my-startup-add-page">
                <Box title={"Startup Detail"}>
                    <div className="row">
                        <Tooltip
                            id="label-tooltip"
                            style={{ maxWidth: "50%", zIndex: 1 }}
                            place="right"
                            variant="light"
                            clickable={true}
                        />
                        {startupDetail?.agencyType !== EStartupTypeCode.GTC_MOVE && (
                            <div className="col-12">
                                <InputWraper
                                    label={[
                                        getInputLabelByInputName(startupDetail?.agencyType, "projectName"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={getInputTooltipByInputName(startupDetail?.agencyType, "projectName")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("projectName")}
                                    component={InputText}
                                />
                            </div>
                        )}
                        {startupDetail?.agencyType === EStartupTypeCode.STORE && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Total Capital Call"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Fill in the capital amount you want to call. Note to fill in the correct capital amount that the business department has approved before so that the project approval process will be faster")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("totalAmountContribution")}
                                    component={InputText}
                                    info={getInputProps("totalAmountContribution")?.value > 0 ? translate("They must spend at least {var1} to be able to call capital", { var1: "100GT" }) : null}
                                />
                            </div>
                        )}
                        <div className="col-md-6 col-12">
                            <InputWraper
                                label={[
                                    translate("Representative Name"),
                                    <div
                                        className="tooltip-info"
                                        data-tooltip-id={`label-tooltip`}
                                        data-tooltip-content={getInputTooltipByInputName(startupDetail?.agencyType, "representativeName")}
                                    >
                                        <Icon.TooltipInfoIcon />
                                    </div>,
                                ]}
                                inputProps={getInputProps("representativeName")}
                                component={InputText}
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <InputWraper
                                label={[
                                    translate("Representative Email"),
                                    <div
                                        className="tooltip-info"
                                        data-tooltip-id={`label-tooltip`}
                                        data-tooltip-content={getInputTooltipByInputName(startupDetail?.agencyType, "representativeEmail")}
                                    >
                                        <Icon.TooltipInfoIcon />
                                    </div>,
                                ]}
                                inputProps={getInputProps("representativeEmail")}
                                component={InputText}
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <InputWraper
                                label={[
                                    translate("Phone Number"),
                                    <div
                                        className="tooltip-info"
                                        data-tooltip-id={`label-tooltip`}
                                        data-tooltip-content={translate("Phone number of the project owner")}
                                    >
                                        <Icon.TooltipInfoIcon />
                                    </div>,
                                ]}
                                inputProps={getInputProps("phoneNumber")}
                                component={InputText}
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <InputWraper
                                label={[
                                    translate("Personal ID"),
                                    <div
                                        className="tooltip-info"
                                        data-tooltip-id={`label-tooltip`}
                                        data-tooltip-content={translate("Personal ID of the project owner")}
                                    >
                                        <Icon.TooltipInfoIcon />
                                    </div>,
                                ]}
                                inputProps={getInputProps("personalId")}
                                component={InputText}
                            />
                        </div>
                        {startupDetail?.agencyType === EStartupTypeCode.STORE && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Business Type"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Brief description of the type of business of your store/business, for example: Grocery")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("kindOfBussiness")}
                                    component={InputText}
                                />
                            </div>
                        )}

                        <div className="col-12">
                            <InputWraper
                                label={[
                                    translate("Description"),
                                    <div
                                        className="tooltip-info"
                                        data-tooltip-id={`label-tooltip`}
                                        data-tooltip-content={getInputTooltipByInputName(startupDetail?.agencyType, "projectDescription")}
                                    >
                                        <Icon.TooltipInfoIcon />
                                    </div>,
                                ]}
                                inputProps={getInputProps("projectDescription")}
                                component={EditorForm}
                            />
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-2 col-6">
                                    <InputWraper
                                        label={[
                                            getInputLabelByInputName(startupDetail?.agencyType, "photoUrl"),
                                            <div
                                                className="tooltip-info"
                                                data-tooltip-id={`label-tooltip`}
                                                data-tooltip-content={getInputTooltipByInputName(startupDetail?.agencyType, "photoUrl")}
                                            >
                                                <Icon.TooltipInfoIcon />
                                            </div>,
                                        ]}
                                        inputProps={getInputProps("photoUrl")}
                                        renderInput={(props) => (
                                            <InputImageSingle
                                                {...props}
                                                isShowImage={false}
                                                onChange={(value: any) => {
                                                    setImageArray((state: any) => {
                                                        if (state.length >= MAX_IMAGE_UPLOAD) {
                                                            CreateAlert({
                                                                message: translate("You can only upload up to {var1} images", { var1: MAX_IMAGE_UPLOAD }),
                                                                type: "warning",
                                                            });
                                                            return state;
                                                        }
                                                        return [...state, value.src];
                                                    });
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                {imageArray.map((element: any, idx: number) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="col-md-2 col-6 image__items"
                                        >
                                            <div
                                                className="image__items__delete-indicator"
                                                onClick={() => handleOnClickDeleteImages(element, idx)}
                                            >
                                                <Icon.TrashIcon />
                                            </div>
                                            <img
                                                src={element}
                                                alt=""
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {startupDetail?.agencyType !== EStartupTypeCode.GTC_TRAVEL && (
                            <>
                                <div className="col-md-6 col-12">
                                    <InputWraper
                                        label={[
                                            getInputLabelByInputName(startupDetail?.agencyType, "licenseFrontUrl"),
                                            <div
                                                className="tooltip-info"
                                                data-tooltip-id={`label-tooltip`}
                                                data-tooltip-content={getInputTooltipByInputName(startupDetail?.agencyType, "licenseFrontUrl")}
                                            >
                                                <Icon.TooltipInfoIcon />
                                            </div>,
                                        ]}
                                        inputProps={getInputProps("licenseFrontUrl")}
                                        component={InputImageSingle}
                                    />
                                </div>
                                <div className="col-md-6 col-12">
                                    <InputWraper
                                        label={[
                                            getInputLabelByInputName(startupDetail?.agencyType, "licenseBackUrl"),
                                            <div
                                                className="tooltip-info"
                                                data-tooltip-id={`label-tooltip`}
                                                data-tooltip-content={getInputTooltipByInputName(startupDetail?.agencyType, "licenseBackUrl")}
                                            >
                                                <Icon.TooltipInfoIcon />
                                            </div>,
                                        ]}
                                        inputProps={getInputProps("licenseBackUrl")}
                                        component={InputImageSingle}
                                    />
                                </div>
                            </>
                        )}
                        {startupDetail?.agencyType !== EStartupTypeCode.GTC_TRAVEL && (
                            <div className="col-md-6 col-12">
                                <iframe
                                    style={{ borderRadius: "8px" }}
                                    src={`https://www.google.com/maps?q=${encodeURIComponent(getInputProps("mapDetail").value)}&output=embed`}
                                    width={"100%"}
                                    height="450"
                                ></iframe>
                                <InputWraper
                                    label={[
                                        translate("Address"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Fill in your exact address, this will help customers easily find the store, the more accurate the address, the easier it is to reach customers, separate the ward - commune - district - district with a comma, for example: No. 5, Au Co street, Ward 10, Tan Binh District, Ho Chi Minh City")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("mapDetail")}
                                    component={InputText}
                                />
                            </div>
                        )}

                        {startupDetail?.agencyType !== EStartupTypeCode.GTC_TRAVEL && (
                            <div className="col-md-6 col-12">
                                <div
                                    className="my-startup-add-page__signature"
                                >
                                    <div className={`my-startup-add-page__signature__overlay ${startupDetail?.ownerSignature == null ? "show" : ""}`}>
                                        <div className="my-startup-add-page__signature__overlay__text">{translate(`Draw the representative's sample signature here. The sample signature will be used for the automatic contract signing feature.`)}</div>
                                    </div>
                                    <img
                                        className="my-startup-add-page__signature__img"
                                        src={startupDetail?.ownerSignature}
                                        alt=""
                                    />
                                </div>
                            </div>
                        )}

                        {startupDetail?.agencyType !== EStartupTypeCode.STORE && <hr className="my-startup-add-page__hr" />}

                        {startupDetail?.agencyType !== EStartupTypeCode.STORE && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Original Price"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Original price")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("originalPrice")}
                                    component={InputText}
                                />
                            </div>
                        )}
                        {startupDetail?.agencyType !== EStartupTypeCode.STORE && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Price"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("price")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("price")}
                                    component={InputText}
                                />
                            </div>
                        )}
                        {startupDetail?.agencyType === EStartupTypeCode.GTC_MOVE && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Vehicle Model"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Example: Honda Civic 2024")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("vehicleModel")}
                                    component={InputText}
                                />
                            </div>
                        )}
                        {startupDetail?.agencyType === EStartupTypeCode.GTC_MOVE && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Number Plate"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Fill in the exact license plate number including the sign, for example: 59-H1 123.45")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("numberPlate")}
                                    component={InputText}
                                />
                            </div>
                        )}
                        {startupDetail?.agencyType === EStartupTypeCode.GTC_TRAVEL && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Sales Quantity"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Limit the quantity you want to sell. If the quantity is unlimited, enter the number 0")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("salesQuantity")}
                                    component={InputText}
                                />
                            </div>
                        )}
                        {startupDetail?.agencyType === EStartupTypeCode.GTC_TRAVEL && (
                            <div className="col-md-6 col-12">
                                <InputWraper
                                    label={[
                                        translate("Sale End Date"),
                                        <div
                                            className="tooltip-info"
                                            data-tooltip-id={`label-tooltip`}
                                            data-tooltip-content={translate("Fill in the time you want to stop selling this service")}
                                        >
                                            <Icon.TooltipInfoIcon />
                                        </div>,
                                    ]}
                                    inputProps={getInputProps("saleEndDate")}
                                    // component={InputDateTime}
                                    renderInput={(props) => (
                                        <InputDateTime
                                            {...props}
                                            // format={"dd/MM/y"}
                                            // disableClock={true}
                                        />
                                    )}
                                />
                            </div>
                        )}

                        <div className="col-12 d-flex justify-content-center">
                            {approvalStatus === 1 && approvalStatus !== 2 && (
                                <Button label={"Submit"} className="mt20" onClick={handleSubmit} isLoading={isSubmitting} disabled={isSubmitting} />
                            )}
                            {approvalStatus !== 1 && approvalStatus !== 2 && (
                                <div className="col-6 d-flex justify-content-between">
                                    <Button label={"Reject"} className="mt20" onClick={() => setOpenReasonForm(true)} buttonType="grey" />
                                    <Button label={"Approve"} className="mt20" onClick={onApprove} isLoading={isSubmitting} disabled={isSubmitting} />
                                </div>
                            )}
                        </div>
                    </div>
                </Box>
            </div>
            {openReasonForm && (
                <ReasonForm
                    onClose={() => {
                        setOpenReasonForm(false);
                    }}
                    payload={{ agencyId: startupId, approvalStatus: 2 }}
                />
            )}
        </div>
    );
};
