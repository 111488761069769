import React, { FC } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './main.scss';
import { PageAuthLogin } from './pages/auth';
import { PageClaimList } from './pages/claim';
import Activities from './pages/config/activities';
import AnnouncementPage from './pages/config/announcement';
import ChangePassword from './pages/config/change-password';
import { MaintenanceConfig } from './pages/config/maintenance/maintenanceConfig';
import PoolConfig from './pages/config/pool-setting';
import RolePermission from './pages/config/role-permission';
import { PageExperienceGameList } from './pages/experience-game';
import { PageFranchiseLeaderTable } from './pages/franchise/leaderTable';
import { PageFranchiseLevelSettingHistory } from './pages/franchise/level-setting-history';
import { PageFranchiseTable } from './pages/franchise/table';
import { PageFranchiseTree } from './pages/franchise/tree';
import {
  GameCreatePage,
  GameEditPage,
  GameListPage,
  OrdersPage,
  SupplierListPage
} from './pages/game-management';
import { SupplierCreatePage } from './pages/game-management/suppliers/add';
import { SupplierEditPage } from './pages/game-management/suppliers/edit';
import { PageGameOrdersList } from './pages/game-orders';
import { PoolPage } from './pages/pool';
import { ReportSystem } from './pages/report/system';
import { AirdropPage } from './pages/transaction/airdrop';
import { DepositSupport } from './pages/transaction/deposit-support/DepositSupport';
import { TransactionDemoList } from './pages/transaction/transaction/TransactionDemoList';
import { TransactionList } from './pages/transaction/transaction/TransactionList';
import { TransactionAdminWithdrawList } from './pages/transaction/withdraw-to-admin-wallet/TransactionAdminWithdrawList';
import { WithdrawProgress } from './pages/transaction/withdraw/WithdrawProgress';
import { UserList } from './pages/user';
import { PageUserAffiliation } from './pages/user/affiliation';
import { PageKYC } from './pages/user/kyc';
import { PageUserKYCList } from './pages/user/kyc/newKYCPage/KYCListNew';
import { UserDetail } from './pages/user/user/UserDetail';
import { IPageProps, withPageWraper } from './pages/wraper';
import PageFranchiseCommissionHistory from './pages/franchise/commission-history';
import TokenBonusConfigPage from './pages/config/token-bonus-config';
import { AffiliatedAgentPage } from './pages/affiliated-agent';
import { AffiliatedAgentCreatePage } from './pages/affiliated-agent/add';
import { AffiliatedAgentEditPage } from './pages/affiliated-agent/edit';
import { StartupManagement } from './pages/startup/startup-management/StartupManagement';
import { StartupDetail } from './pages/startup/startup-detail';
import { StartupInvestmentHistory } from './pages/startup/investment-history/InvestmentHistory';
import { PageDividend } from './pages/dividend';
import { OrderManagement } from './pages/product-management/order-management/orderManagement';
import { OrderDetail } from './pages/product-management/order-detail/orderDetail';
import { WaitingApprovalProducts } from './pages/product-management/waiting-approval-products/WatingApporvalProducts';
import { RejectedProducts } from './pages/product-management/rejected-products/RejectedProducts';
import { ApprovedProducts } from './pages/product-management/approved-products/ApprovedProducts';
import { ProductDetail } from './pages/product-management/product-detail/index';
import { AgencyRevenue } from './pages/affiliated-agent/revenue-agency';
import { RevenueAgencyDetail } from './pages/affiliated-agent/revenue-agency/detail-revenue-agency';
import { NewOrderDetail } from './pages/product-management/order-detail-new';
import { DeletedProduts } from './pages/product-management/deleted-products/DeletedProducts';
import ActivityAdmin from './pages/config/admin-activity';
import { ApprovalForCallingCapital } from './pages/startup/approval-for-calling-capital';
import { Contract } from './pages/startup/contract';
import { VendingMachineList } from './pages/transaction/vending-machine';
import { VendingMachineConfigPage } from './pages/config';
import { GrantLimitHistoryList } from './pages/transaction/grant-limit-history';
import { TransactionByMachineIdList } from './pages/transaction/transaction-by-machine-id';

// import { VerifyKYCPage } from './pages/user/kyc/verifyKYCPage';
export const Routes = {
  // dashboard: {
  //     path: '/',
  //     component: PageDashboard,
  // },
  // dashboard: {
  //   path: "/",
  //   component: UserList,
  // },
  dashboardSuperAdmin: {
    path: '/',
    component: UserList
  },
  userList: {
    path: '/users',
    component: UserList
  },
  userKYCList: {
    path: '/users/kycs',
    component: PageUserKYCList
  },
  // userKYCVerify: {
  //   path: '/users/kycs/verify',
  //   component: VerifyKYCPage
  // },
  userAffiliation: {
    path: '/users/affiliation',
    component: PageUserAffiliation
  },
  franchiseTable: {
    path: '/franchise',
    component: PageFranchiseTable
  },
  franchiseTree: {
    path: '/franchise/tree',
    component: PageFranchiseTree
  },
  franchiseLevelSettingHistory: {
    path: '/franchise/level-setting-history',
    component: PageFranchiseLevelSettingHistory
  },
  franchiseLeader: {
    path: '/franchise/leader',
    component: PageFranchiseLeaderTable
  },
  franchiseCommissionHistory: {
    path: '/franchise/commission-history',
    component: PageFranchiseCommissionHistory
  },
  dividends: {
    path: '/dividends',
    component: PageDividend
  },
  userDetail: {
    path: '/users/:email',
    component: UserDetail,
    renderPath: (email: string) => `/users/${email}`
  },
  transactionList: {
    path: '/transactions',
    component: TransactionList
  },
  transactionDemoList: {
    path: '/transactions/demo',
    component: TransactionDemoList
  },
  withdraw: {
    path: '/transactions/withdraws',
    component: WithdrawProgress
  },
  vendingMachine: {
    path: '/transactions/vending-machine',
    component: VendingMachineList
  },
  grantLimitHistory: {
    path: '/transactions/grant-limit-history',
    component: GrantLimitHistoryList
  },
  transactionByMachineId: {
    path: '/transactions/vending-machine/:id/trans',
    component: TransactionByMachineIdList,
    renderPath: (id: string) => `/transactions/vending-machine/${id}/trans`
  },
  AirdropPage: {
    path: '/transactions/airdrop',
    component: AirdropPage
  },
  kyc: {
    path: '/kyc',
    component: PageKYC
  },
  reportSystem: {
    path: '/reports/system',
    component: ReportSystem
  },
  depositSupport: {
    path: '/transactions/deposit-support',
    component: DepositSupport
  },
  TransactionAdminWithdrawList: {
    path: '/transactions/transactions-withdraw-to-admin-wallet',
    component: TransactionAdminWithdrawList
  },
  withdrawConfig: {
    // path: "/server-config/withdraw",
    // component: WithdrawConfig,
    path: '/server-config/maintenance',
    component: MaintenanceConfig
  },
  maintenanceConfig: {
    path: '/server-config/maintenance',
    component: MaintenanceConfig
  },
  rolePermission: {
    path: '/server-config/role-permission',
    component: RolePermission
  },
  changePassword: {
    path: '/server-config/change-password',
    component: ChangePassword
  },
  activities: {
    path: '/server-config/activities',
    component: Activities
  },
  poolSetting: {
    path: '/server-config/pool',
    component: PoolConfig
  },
  Announcement: {
    path: '/server-config/announcement',
    component: AnnouncementPage
  },
  tokenBonusConfig: {
    path: '/server-config/token-bonus-config',
    component: TokenBonusConfigPage
  },
  ClaimList: {
    path: '/claim',
    component: PageClaimList
  },
  GameOrdersList: {
    path: '/game-orders',
    component: PageGameOrdersList
  },
  OrdersPage: {
    path: '/game-management/orders',
    component: OrdersPage
  },
  SupplierListPage: {
    path: '/game-management/supplier',
    component: SupplierListPage
  },
  SupplierCreatePage: {
    path: '/game-management/supplier/create',
    component: SupplierCreatePage
  },
  SupplierEditPage: {
    path: '/game-management/supplier/edit/:id',
    component: SupplierEditPage
  },
  GameListPage: {
    path: '/game-management/game',
    component: GameListPage
  },
  GameCreatePage: {
    path: '/game-management/game/create',
    component: GameCreatePage
  },
  GameEditPage: {
    path: '/game-management/game/edit/:id',
    component: GameEditPage
  },
  ExperienceGameList: {
    path: '/experience-game',
    component: PageExperienceGameList
  },
  Pool: {
    path: '/pool',
    component: PoolPage
  },
  affiliatedAgent: {
    path: '/affiliated-agent',
    component: AffiliatedAgentPage
  },

  revenueAgency: {
    path: '/affiliated-agent/revenue-agent',
    component: AgencyRevenue
  },
  detailRevenueAgency: {
    path: '/revenue-agent/detail/:id/:name',
    component: RevenueAgencyDetail,
    renderPath: (id: string, name: string) =>
      `/revenue-agent/detail/${id}/${name}`
  },

  // affiliatedAgentCreate: {
  //   path: "/affiliated-agent/create",
  //   component: AffiliatedAgentCreatePage,
  // },
  affiliatedAgentEdit: {
    path: '/affiliated-agent/:id/edit',
    component: AffiliatedAgentEditPage
  },
  startupList: {
    path: '/startup/list',
    component: StartupManagement
  },
  startupReview: {
    path: '/startup/review'
    // component: ,
  },
  startupDetail: {
    path: '/startup/detail/:id',
    component: StartupDetail,
    renderPath: (id: string) => `/startup/detail/${id}`
  },
  // startupInvestmentHistory: {
  //   path: '/startup/investment-history',
  //   component: StartupInvestmentHistory
  // },
  startupApprovalForCallingCapital: {
    path: '/startup/approval-for-calling-capital',
    component: ApprovalForCallingCapital
  },
  startupContract: {
    path: '/startup/startup-contract',
    component: Contract
  },
  WaitingApprovalProducts: {
    path: '/product-management/waiting-approval-products',
    component: WaitingApprovalProducts
  },
  ApprovedProducts: {
    path: '/product-management/approved-products',
    component: ApprovedProducts
  },
  RejectedProducts: {
    path: '/product-management/rejected-products',
    component: RejectedProducts
  },
  DeletedProducts: {
    path: '/product-management/delected-products',
    component: DeletedProduts
  },
  ProductDetail: {
    path: '/product-management/detail/:id',
    component: ProductDetail,
    renderPath: (id: string) => `/product-management/detail/${id}`
  },
  orderManagement: {
    path: '/product-management/order-management',
    component: OrderManagement
  },
  orderDetail: {
    path: '/product-management/order-management/order-detail/:id',
    component: NewOrderDetail,
    // component: OrderDetail,

    renderPath: (id: string) =>
      `/product-management/order-management/order-detail/${id}`
  },
  adminActivities: {
    path: '/server-config/admin-activities',
    component: ActivityAdmin
  },
  VendingMachineConfig: {
    path: "/server-config/vending-machine-config",
    component: VendingMachineConfigPage,
  },
};

export const AppRoutes: FC = () => {
  return (
    <Router>
      <Switch>
        {Object.values(Routes).map((item: any, key) => {
          return (
            <Route
              key={key}
              exact
              path={item.path}
              component={withPageWraper((props: IPageProps) => {
                return <item.component {...props} />;
              })}
            />
          );
        })}

        <Route path="/auth/login" component={PageAuthLogin} />
      </Switch>
    </Router>
  );
};
