import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export interface IFranchiseWraperProps extends RouteComponentProps {}

export const withStartupWraper = (Component: FC<IFranchiseWraperProps>) => (props: IFranchiseWraperProps) => {
  return (
    <div className="withStartupWraper">
      <div className="TabLinks">
        <NavLink to={Routes.startupList.path} exact={true}>
          <Icon.Table />
          Startup Management
        </NavLink>

        {/* <NavLink to={Routes.startupInvestmentHistory.path} exact={true}>
        <Icon.HistoryIcon/>
          Investment History
        </NavLink> */}

        {/* <NavLink to={Routes.startupApprovalForCallingCapital.path} exact={true}>
        <Icon.Revenue/>
          Approval For Calling Capital
        </NavLink>

        <NavLink to={Routes.startupContract.path} exact={true}>
        <Icon.Contract/>
          Contract
        </NavLink> */}

        {/* <NavLink to={""} exact={true}>
          <Icon.Tree />
          Reivew
        </NavLink> */}
      </div>
 
      <Component {...props} />
    </div>
  );
};
