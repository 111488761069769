import capitalize from "lodash/capitalize";
import moment from "moment";
import React, { memo, useState } from "react";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { getLocaleKey, translate } from "../../../languages";
import { Button, Icon, NumberUtils, ObjectUtils, Table } from "../../../modules";
import { BankService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withTransactionWraper } from "../wraper";
import XLSX from "xlsx";

export const TransactionList = withTransactionWraper(
  memo(
    () => {
      const [currentData, setCurrentData] = useState(null as any);
      const [params, setParams] = useState([] as any[]);

      const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
      const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

      const coinsState = useSelector((state) => state.coins);
      const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
        arr.map((item: any) => ({
          label: item.name,
          value: item.coinId,
        }))
      );
      // const UsdId = 2;
      // const ButId = 5;
      // const DemoId = 4;
      // const Gp2Id = 5;
      const GTCId = 9;
      const GTMId = 11;

      enum ENetWork {
        BEP20 = "BEP20",
        TRC20 = "TRC20",
        ERC20 = "ERC20",
      }

      let structure = [
        {
          name: "Coin",
          key: "coinId",
          className: "coin",
          render: (item: any) => {
            const coin = coinsOptions.find((v: any) => v.value === item.coinId);
            let coinAvatar;
            switch (coin?.value) {
              case 2: {
                coinAvatar = "usd.png";
                break;
              }
              case 3: {
                coinAvatar = "usdt.png";
                break;
              }
              case 4: {
                coinAvatar = "usd.png";
                break;
              }
              case 5: {
                coinAvatar = "usd.png";
                break;
              }
              case 9: {
                coinAvatar = "gtc.png";
                break;
              }
              case 11: {
                coinAvatar = "gtm.png";
                break;
              }
              case 12: {
                coinAvatar = "usdt.png";
                break;
              }
            }
            return (
              <>
                <img className="coin-avatar" src={`/assets/images/coins/${coinAvatar}`} alt="" />
                <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
              </>
            );
          },
        },
        {
          name: "Time",
          key: "created",
          render: (item: any) => {
            return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
          },
        },
        {
          name: "Email",
          key: "email",
          className: "email",
        },
        {
          name: "Amount",
          key: "value",
          render: (item: any) => {
            const { balanceAfter, balanceBefore } = item;
            let isIncrease = false;
            if (balanceAfter > balanceBefore) isIncrease = true;
            return (
              <span className={isIncrease ? "textSuccess" : "textDanger"}>
                {isIncrease ? "+" : "-"}
                {NumberUtils.toFormatNumber(+item?.value, +item.coinId === GTCId || +item.coinId === GTMId ? 0 : +getEnv("NUMBER_DECIMAL_DISPLAY"))}
              </span>
            );
          },
        },
        {
          name: "Balance After",
          key: "balanceAfter",
          render: (item: any) => {
            return <span>{NumberUtils.toFormatNumber(+item?.balanceAfter, +getEnv("NUMBER_DECIMAL_DISPLAY"))}</span>;
          },
        },
        {
          name: "Type",
          key: "transactionTypeId",
          render: (item: any) => {
            if (item.transactionType.name === "EXGAME_REFUND") return capitalize("EXPERIECE_GAME_REFUND".replace(/_/g, " "));
            if (item.transactionType.name === "RECEIVE_DIVIDENDS") return capitalize("SPENDING_BUSINESS_PROFITS".replace(/_/g, " "));
            return ObjectUtils.getIn(capitalize(item.transactionType.name.replace(/_/g, " ")), "", "--");
          },
          //render: (item:any) => ObjectUtils.getIn(item, 'transactionType.name', '--'),
        },
        // {
        //     name: translate("description"),
        //     key: "description",
        // },
        {
          name: "TxHash",
          key: "transactionHash",
          render: (item: any) => {
            const hash = ObjectUtils.getIn(item, "transactionHash");
            if (hash) {
              switch (item.network) {
                case ENetWork.BEP20: {
                  return (
                    <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                      Click me
                    </a>
                  );
                }
                case ENetWork.TRC20: {
                  return (
                    <a href={`${getEnv("TRON_SCAN")}${hash}`} target="__blank">
                      Click me
                    </a>
                  );
                }
                case ENetWork.ERC20: {
                  return (
                    <a href={`${getEnv("ETHER_SCAN")}${hash}`} target="__blank">
                      Click me
                    </a>
                  );
                }
                default: {
                  return (
                    <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                      Click me
                    </a>
                  );
                }
              }
            }
            return "--";
          },
        },
        // {
        //   name: "IP",
        //   key: "ip",
        //   render: (item:any) => (item?.ip != null ? item?.ip : "--"),
        // },
      ];

      return (
        <div className="TransactionList">
          <div className="button-container">
            <Button
              disabled={!currentData}
              label="Export to Excel"
              buttonType="primary"
              className="mb15"
              onClick={async () => {
                const response = await AdminService.getListTransactions({
                  ...params,
                  page: 1,
                  numberOfTransactionsPerPage: 1000000,
                });

                const data = response.data;

                const fileHead = structure.map((v) => v.name);
                const dataExport = [
                  fileHead,
                  ...data.map((item: any) =>
                    structure.map((column, index) => {
                      if (column.key === "coinId") {
                        const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                        return ObjectUtils.getIn(coin, "label", "--");
                      }
                      if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
                      if (column.key === "value") {
                        const { balanceAfter, balanceBefore } = item;
                        let isIncrease = false;
                        if (balanceAfter > balanceBefore) isIncrease = true;
                        return `${isIncrease ? "+" : "-"}${NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}`;
                      }
                      if (column.key === "transactionTypeId") {
                        return ObjectUtils.getIn(capitalize(item.transactionType.name.replace(/_/g, " ")), "", "--");
                      }
                      if (column.key === "transactionHash") {
                        const hash = ObjectUtils.getIn(item, "transactionHash");
                        if (hash) {
                          switch (item.network) {
                            case ENetWork.BEP20: {
                              return `${getEnv("BSC_SCAN")}${hash}`;
                            }
                            case ENetWork.TRC20: {
                              return `${getEnv("TRON_SCAN")}${hash}`;
                            }
                            default: {
                              return "";
                            }
                          }
                        }
                      }
                      if (!column.key) return "";
                      return item[column.key];
                    })
                  ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(
                  wb,
                  `Transaction-List-${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
                  { type: "binary" }
                );
              }}
            />
            <div className="infor-container">
              <div className="total-amount">
                <div className="total-amount__icon">
                  <Icon.TotalVolumeIcon />
                </div>
                <div className="total-amount__content">
                  {NumberUtils.toFormatNumber(currentData?.totalValue ?? 0, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                  <span className="sub-text">&nbsp;&nbsp;TOTAL AMOUNT</span>
                </div>
              </div>
            </div>
          </div>
          <Table
            hasOrderColumn
            hasSearchButton={true}
            itemPerPages={10}
            filters={[
              {
                name: "Coin",
                key: "coinId",
                input: (e) => <TableFilterInputSelect isClearable={false} {...e} options={coinsOptions.filter((item: any) => item.value !== 6)} />,
                defaultValue: 0,
              },
              {
                name: translate("time"),
                key: "created",
                input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                defaultValue: {
                  fromDate: startOfMonth, //new Date(last1MothDate),
                  toDate: endOfDay, //new Date(),
                },
              },
              {
                name: "Email",
                key: "email",
                input: TableFilterInputText,
              },
              // {
              //     name: "Amount",
              //     key: "amount",
              //     input: TableFilterInputNumber,
              // },
              {
                name: translate("type"),
                key: "transactionTypeId",
                input: (s) => <TableFilterInputSelect {...s} options={BankService.getTransactionTypeOptions()} />,
              },
            ]}
            structure={structure}
            fetchData={async (state) => {
              let params = { ...state };
              if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
              if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
              setParams(params);

              return AdminService.getListTransactions({
                page: params.pageNumber,
                numberOfTransactionsPerPage: params.limit,
                ...params,
              }).then((res) => {
                setCurrentData(res);
                return res;
              });
            }}
          />
        </div>
      );
    },
    () => true
  )
);
